@import '../../../scss/variable.scss';

.backdrop {
	opacity: 0.5;
	pointer-events: none;
}

.app-footer {
	min-height: 283px;
	height: 283px;
	width: 100%;
	// font-family: global-variable-exists($name: );
	*,
	*:before,
	*:after {
		box-sizing: border-box;
	}

	.cardImages {
		width: 50px;
		height: auto;
	}

	.footer {
		display: flex;
		flex-flow: row wrap;
		padding: 28px 10px;
		color: $white-color;
		background-color: var(--primary-color);

		@media only screen and (min-device-width: 320px) and (max-device-width: 1024px) {
			padding: 28px 16px;
			.nav {
				display: block;
				padding-top: 0;
			}
		}
	}

	.footerLogo {
		margin: 10px auto 20px;
		// height: 36px;
		// width: 139px;
		// height: 190px;
		// width: 250px;
		margin-top: 30px;
		@media only screen and (min-device-width: 320px) and (max-device-width: 1024px) {
			height: unset;
			width: 100px;
		}
	}
}

.footer > * {
	flex: 1 100%;
}

.l-footer {
	margin-bottom: 2em;
	padding-left: 75px;
	@media only screen and (min-device-width: 320px) and (max-device-width: 1024px) {
		margin-bottom: 0;
	}

	.navbar-brand img {
		width: 250px;
	}
}

h2 {
	font-weight: 400;
	font-size: 15px;
}

.footer ul {
	list-style: none;
	padding-left: 0;
}

.footer li {
	line-height: 1.5em;
	margin-bottom: 1em;
}

.footer a {
	text-decoration: none;
}

.footer a:hover {
	color: $light-green;
}

.m-footer {
	display: flex;
	flex-flow: row wrap;
}

.m-footer > * {
	flex: 1 50%;
}

.right-link {
	margin-top: 60px;
}

.box {
	margin-top: 30px;
}

.box a {
	color: $white-color;
	font-size: 14px;

	@media only screen and (max-device-width: 767px) {
		display: block;
		height: 35px;
	}
}

.box p {
	margin: 8px;
}

.box .footer-header {
	color: $white-color;
	a {
		font-size: 14px;
		cursor: pointer;
		font-family: 'Roboto';
		font-weight: 400;
	}
}

.footer-header:hover {
	text-decoration: underline;

	a {
		color: $white-color;
	}
}

.b-footer {
	text-align: center;
	padding-top: 52px;
	border-top: 1px solid hsla(0, 0%, 59%, 0.2);
	background-color: hsla(225, 27%, 12%, 1);
	color: #8b8b8b;
}

.b-footer p {
	padding: 0;
	margin-top: -40px;
	font-size: 12px;
	font-family: 'Roboto';
	font-weight: 300;
}
.b-footer p:nth-of-type(2) {
	margin-top: -17px;

	a {
		color: #fff;
	}
}

.l-footer p {
	padding-right: 20%;
	color: $white-color;
	font-size: 12px;
	line-height: 18px;
	margin-bottom: 0 !important;
}

.effect {
	a {
		text-decoration: none;
		width: 60px;
		height: 60px;
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 50%;
		margin-right: 20px;
		font-size: 25px;
		overflow: hidden;
		position: relative;
		color: $dark-color; //or change to your own color
		border: 2px solid $dark-color; //or change to your own color

		i {
			position: relative;
			z-index: 1;
		}

		&:last-child {
			margin-right: 0px;
		}

		&:before {
			//content: "";
			display: inline-block;
			height: 100%;
			vertical-align: middle;
		}

		i {
			display: inline-block;
		}
	}
}

.fa {
	width: 30px;
	text-align: center;
	text-decoration: none;
	margin: 5px 2px;
	border-radius: 50%;
}

.fa:hover {
	opacity: 0.7;
}

.fa-facebook {
	background: #3b5998;
	color: white;
}

.fa-twitter {
	background: #55acee;
	color: white;
}

.fa-linkedin {
	background: #007bb5;
	color: white;
}

.fa-youtube {
	background: #bb0000;
	color: white;
}

.footer-icons a {
	display: inline-block;
	width: 40px;
	height: 40px;
	border: none;
	text-align: center;
	color: $white-color;
	font-size: 16px;
	font-weight: normal;
	line-height: 40px;
	border-radius: 50%;
	margin-right: 18px;
	vertical-align: middle;
	transition: all 0.3s linear 0s;

	&:after {
		//content: "";
		display: block;
		width: 90%;
		height: 90%;
		top: -110%;
		left: 0;
		right: 0;
		margin: auto;
		position: absolute;
		background-color: $white-color;
		border-radius: 50%;
	}

	&:hover {
		opacity: 0.5;

		&:after {
			top: 5%;
			transition: all 0.6s linear 0s;
		}
	}
}

.footer__header {
	font-size: 17px;
	font-weight: 500;
	color: $white-color;
	font-family: var(--heading-font-family);
	margin-bottom: 40px;

	@media only screen and (min-device-width: 320px) and (max-device-width: 1024px) {
		margin-bottom: 35px;
	}
}

.r-footer {
	padding-right: 150px;
	margin-top: 30px;
}

.r-footer img {
	margin-bottom: 10px;
}

.r-footer li {
	line-height: 1em;
}

.l-footer {
	flex: 1 0px;
}

.m-footer {
	flex: 2 0px;
}

@media screen and (min-width: 600px) {
	.m-footer > * {
		flex: 1;
		flex-direction: column;
	}
}

@media only screen and (min-device-width: 320px) and (max-device-width: 1024px) {
	.l-footer {
		padding-left: 0 !important;
		flex-direction: column;
	}
}

@media only screen and (min-device-width: 1080px) and (max-device-width: 1180px) {
	.l-footer {
		padding-left: 40px !important;
		flex-direction: column;
	}
}

@media only screen and (min-device-width: 320px) and (max-device-width: 1024px) {
	.l-footer p {
		padding-right: 0 !important;
	}
}

@media only screen and (min-device-width: 320px) and (max-device-width: 1180px) {
	.r-footer {
		padding-right: 0 !important;
		flex-direction: column;
	}
}

@media only screen and (min-device-width: 320px) and (max-device-width: 1024px) {
	.footer-icons a {
		font-size: 15px;
		width: 35px;
		height: 35px;
		line-height: 35px;
	}
}

@media only screen and (min-device-width: 320px) and (max-device-width: 1024px) {
	.footer {
		flex-direction: column;
	}
}

@media only screen and (max-width: 400px) {
	.l-footer {
		padding-left: 0;
	}
}

@media only screen and (max-width: 400px) {
	.r-footer {
		padding-right: 0;
	}

	@media (min-width: 768px) {
		.l-footer {
			padding-left: 0;
		}
	}
}

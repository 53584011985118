@import '../../../scss/variable.scss';

.admin-alert-modal {
	opacity: 1;
	pointer-events: none;
	.modal-content {
		top: 0 !important;
		left: 0 !important;
		width: 100% !important;
	}
}
.short-search {
	background: $header-background;
	border-top: 2px solid #e4e1e1;
	height: 58px;
	background: #f7f6f6;
	padding-top: 7px;
	position: absolute;
	left: 0px;
	width: 100%;
	box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.07);
	@media screen and (max-width: 1180px) {
		display: none !important;
	}
}

.app-header {
	background: var(--primary-color);
	height: auto;
	position: sticky;
	top: 0px;
	z-index: 1024;
	box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.15);

	@media screen and (min-width: 767px) {
		height: 85px;
	}

	.navbar-brand {
		padding-top: 0;
		padding-bottom: 0;
	}
	.user-actions {
		.dropdown {
			position: relative;

			&::after {
				position: absolute;
				top: 50%;
				transform: translateY(-50%);
			}
		}

		&:hover {
			.dropdown-menu {
				display: block;
				left: -31px;
			}
		}
	}
	.container-fluid {
		max-width: 1800px;
		padding-left: 35px;
		padding-right: 20px;

		img {
			height: 36px;
			margin-right: 40px;
		}

		.profile-pic {
			width: 40px;
			height: auto;
			margin-right: 0;
		}

		.navbar-nav {
			margin-right: 0 !important;
			.nav-item {
				display: inline-block;

				.nav-link {
					color: var(--white-color);
					font-family: var(--heading-font-family);
					font-size: 18px;
					border: 3px solid transparent;
					background: none;
					font-weight: 500;
				}
				.card {
					box-shadow: none;
					min-width: max-content;
					.hover-highlight {
						min-width: 130px;
						&:hover {
							background-color: #e7e7e7;
							border-radius: 4px;
							.dropdown-item {
								&:hover {
									background-color: unset;
									color: #000;
								}
							}
						}
					}
					.dropdown-item {
						svg path {
							fill: #fff;
						}
						&:hover {
							svg {
								margin-right: 7px;

								path {
									fill: var(--black-color);
									height: 12px;
									width: 12px;
									float: right;
									margin-top: 6px;
									padding-right: 5px;
								}
							}
						}
					}
					.list-group {
						position: relative;
						padding: 0 15px 0 11px;
						.list-group-item {
							cursor: pointer;
							padding: 0;
							border: none;
							min-width: 100px;
							display: inline;
							font-family: var(--heading-font-family, 'Ubuntu');
							font-weight: 300;
						}
						svg {
							position: absolute;
							display: none;
						}
						&:hover {
							background-color: #e7e7e7;
							text-decoration: none;
							.list-group-item {
								background-color: #e7e7e7;
							}
							a {
								color: #000;
								display: block;
							}
							svg {
								height: 12px;
								width: 12px;
								float: right;
								fill: var(--black-color);
								margin-top: 7px;
								display: inline;
								position: absolute;
								right: 0px;
								top: 3px;
								margin-right: 7px;

								path {
									fill: var(--black-color);
									height: 12px;
									width: 12px;
									float: right;
									margin-top: 6px;
									padding-right: 5px;
								}
							}
						}
					}
				}
			}
			.nav-dropdown {
				// padding-right: 80px;
				.dropdown-panel:hover {
					.dropdown-menu {
						display: none;
					}
				}
			}
			.menu-header {
				padding-right: 50px;
				margin-left: 30px;
				margin-right: 10px;
				padding-top: 4px;
			}

			.nav-dropdown {
				.dropdown:hover .dropdown-menu,
				.dropdown-menu.show {
					display: block;
				}
			}
			.nav-dropdown:first-child {
				.dropdown:hover .dropdown-menu,
				.dropdown-menu.show {
					display: flex;
					.nav-item {
						width: 100%;
						.dropdown-item {
							color: black;
							background-color: $white-color;
							text-decoration: none;
						}
					}
					.card + .card {
						margin-left: 15px;
					}
				}
				.dropdown-menu.show {
					display: none;
				}

				.dropdown-panel:hover {
					.dropdown-menu {
						display: none;
					}
				}
			}

			.nav-item {
				color: black;
				display: inline-block;
				position: relative;
				margin-right: 10px;
				font-family: var(--heading-font-family, 'Ubuntu');
				min-width: 100px;
				font-weight: 300;
			}
			.nav-item > a {
				color: var(--black-color);
				font-family: var(--heading-font-family, 'Ubuntu');
				// text-transform: lowercase;
				&::first-letter {
					text-transform: capitalize;
				}
			}
			.nav-item > a :hover {
				color: black;
				height: 40px;
				// background-color: white;
			}
			.nav-item > .show-my-profile {
				display: block;
			}
			.nav-item > .hide-my-profile {
				display: none;
			}
			.nav-item:hover > .nav-link,
			.nav-item:hover > a {
				svg {
					fill: var(--black-color);
					height: 12px;
					width: 12px;
					float: right;
					margin-top: 7px;
				}
			}
			.highlight-cell,
			.highlight-cell:hover {
				a {
					color: $dark-orange;
				}
			}
			.highlight-cell:hover::after {
				background-color: #f17026;
			}
		}

		.header-buttons {
			height: auto;
			color: #fff;
			align-items: center;
			margin-left: 20px;

			svg {
				color: #fff !important;
				height: 30px !important;
				width: 25px !important;
				fill: #fff !important;
				margin-right: 10px;
			}
		}
	}
	.header-buttons {
		margin-left: 0px;
	}
	.header-buttons1 {
		margin-left: 0;
	}
	.header-buttons3 {
		margin-left: 15px;
		.reg-login {
			background-color: var(--cta-color-1);
		}
	}
	.dropdown-item {
		font-weight: 300;
	}
	.navbar {
		padding: 20px 0;

		.navbar-collapse {
			form {
				position: absolute;
				right: 360px;
				width: 50%;
				@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
					width: 42%;
					right: 235px;
					top: 9px;
				}
				.search-box {
					width: 100%;
					position: relative;
					.search-box-field {
						background-color: $green-background;
						width: 100%;
					}
				}
			}
		}
		.show-header-search {
			form {
				transition: width 0.5s;
				width: 15%;
				@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
					width: 22%;
				}
			}
		}
	}

	.dropdown > .nav-link {
		padding: 0;
		color: #fff;
		text-decoration: none;
		font-size: 16px;
	}
	.search-icon {
		height: 30px;
		width: 40px;
		margin-left: -10px;
		color: $white-color;
		background: $dark-orange;
		border-top-right-radius: 4px;
		border-bottom-right-radius: 4px;
		cursor: pointer;
	}
	.search-icon > svg {
		width: 18px;
		height: 27px;
		margin: 0px 10px;
	}
	.nav-item:hover > .nav-link {
		border: none;
		opacity: 0.5;
	}
	.nav-item > a:hover {
		text-decoration: none;
		border: none;
	}

	.nav-item > a:hover,
	.dropdown > .nav-link:hover {
		// line-height: 24px;
		text-decoration: none;
		border: none;
	}
	.header-buttons > a {
		margin: 0 5px;
		border-radius: 4px;
		color: $white-color;
		cursor: pointer;
	}
	.header-buttons:hover::after {
		border-bottom: none;
	}
	.header-buttons button {
		margin: 0 20px;
		background-color: $light-green;
		border: 1px solid $light-green;
		height: 34px;
		font-size: 14px;
		line-height: 18px;
	}

	.header-buttons > a > svg {
		overflow: hidden;
		vertical-align: bottom;
		height: 25px;
		width: 25px;
		padding: 4px;
		color: $white-color;
	}

	.dropdown-toggle::after {
		content: url(./down-arrow.svg) !important;
		margin-left: 10px;
		border: none;
		vertical-align: unset;
	}
	.nav-item > .form-inline {
		position: absolute;
		top: 15px;
		margin-left: 25px;
	}
	.form-inline > input {
		height: 30px;
	}

	.nav-tabs {
		border: none;
		align-items: baseline;
	}
	.subMenuTypes1 {
		font-size: 18px;
		font-weight: 500;
	}
	.val-link {
		font-size: 21px;
		font-weight: 500;
		color: var(--white-color) !important;
	}
	.sell-button {
		-webkit-appearance: none;
		-moz-appearance: none;
		appearance: none;
	}
	@media only screen and (min-width: 768px) {
		.sell-button {
			font-family: var(--heading-font-family);
			font-size: 18px;
			font-weight: 400;
			text-decoration: none;
		}
	}

	.reg-login a:hover {
		color: $white-color;
	}
	/* Smartphones (portrait and landscape) ----------- */
	@media only screen and (min-device-width: 320px) and (max-device-width: 767px) {
		/* STYLES GO HERE */
		.app-header {
			height: 80px !important;
			// background: $header-background;
		}
		.container-fluid {
			padding-left: 0 !important;
			padding-right: 0 !important;
			.navbar-custom {
				display: none;
			}
			.sidebar-menu {
				img {
					width: 131px;
					height: 62px;
					padding: 10px 0;
				}
				.header-buttons {
					padding: 18px 0;
					position: relative;
					float: right;
					width: 30%;
				}
			}
			.search-mobile-view {
				display: block;
			}
		}
		.navbar-collapse {
			background-color: $green-background;
			// border-bottom: 1px solid $light-green;
		}
		.navbar-dark .navbar-toggler {
			position: absolute;
			left: 0;
			border-color: rgba(0, 0, 0, 0.1) !important;
		}
		.navbar-custom {
			justify-content: center !important;
		}
		.header-buttons button {
			font-size: 14px;
			padding: 4px 25px 4px 20px;
			margin-left: 4px;
			font-weight: 700;
			font-style: normal;
		}
		.navbar-collapse > .navbar-nav > .nav-item:nth-child(1) {
			margin-top: 50px;
		}
		.nav-item {
			//   padding: 19px 10px;
			//border-bottom: 0.5px solid $light-green;
		}
		.header-buttons.nav-item {
			border-bottom: none;
		}
		.navbar button {
			display: none;
		}
		.short-search {
			display: none;
		}
	}
	/* iPads (portrait and landscape) ----------- */
	@media only screen and (min-device-width: 768px) and (max-device-width: 1180px) {
		/* STYLES GO HERE */
		.app-header {
			height: 80px !important;
		}
		img {
			width: 131px;
			height: 62px;
			//   margin-top: 10px;
		}
		.container-fluid {
			.navbar-nav {
				.nav-dropdown:first-child {
					.dropdown:hover {
						.dropdown-menu {
							display: none;
						}
					}
				}
				.nav-dropdown:first-child {
					.show:hover {
						.show {
							display: flex;
							margin-left: -40px !important;
						}
					}
				}
				.nav-dropdown {
					.dropdown:hover {
						.dropdown-menu {
							display: none;
						}
					}
				}
				.nav-dropdown {
					.show:hover {
						.show {
							display: inline-block;
						}
					}
				}
			}
		}
		.header-buttons {
			display: none !important;
		}
		.header-buttons1 {
			display: none !important;
		}

		.header-buttons3 {
			display: none !important;
		}
		// .header-buttons1{
		//  margin-left: -40px;
		//  margin-right: 35px;
		// }
		// .header-buttons1, .header-buttons1{
		//   margin-top: -5px;
		// }
		.search-box.form-group .search-icon-link {
			top: 7px;
			left: auto;
			z-index: 1;
		}
		.search-box.form-group {
			float: right;
			margin-right: 85px;
			padding-top: 2px;
		}
		.container-fluid .navbar-nav {
			display: none;
			height: 45px;
		}

		.navbar-nav .dropdown .dropdown-menu {
			display: none;
		}

		.nav-item:hover::after {
			padding-top: 2px;
		}
		.div-desk-img {
			display: none;
		}
	}
	.panel {
		width: 200px;
		height: 100px;
		background: gray;
		margin-top: 10px;
	}

	.login-div-enter {
		height: 0px;
		transition: height 0.3s ease;
		&.login-div-enter-active {
			height: 300px;
		}
		&.login-div-enter-done {
			height: auto;
		}
		&.login-div-exit {
			height: 0px;
		}
	}

	.profile-pic {
		width: 40px;
		height: auto;
		border-radius: 40px;
	}
	.side-menu-header2 {
		padding-top: 10px;
		margin-left: -30px;
	}
}

.auctions-list {
	font-weight: 900;
}
.transitionDiv {
	height: auto;
	margin: 0 auto;
}

@keyframes inAnimation {
	0% {
		opacity: 0;
		visibility: hidden;
	}
	100% {
		opacity: 1;
		visibility: visible;
	}
}

@keyframes outAnimation {
	0% {
		opacity: 1;
	}
	100% {
		opacity: 0;
		visibility: hidden;
	}
}

@import './variable.scss';

@import './bootstrap-override';
@import '~bootstrap/scss/bootstrap';
@import '~react-multi-carousel/lib/styles.css';
@import './floating-input.scss';
@import './form-input.scss';
@import './button.scss';
@import './custom-scroll.scss';
@import './modal.scss';
@import './custom-checkbox.scss';

* {
	padding: 0;
	margin: 0;
	box-sizing: border-box;
}

@media (pointer: fine) {
	a span {
		display: none;
	}
	a:hover span {
		display: inline-block;
	}
}

body {
	//   background-color: $light-grey;
	font-family: $paragraph-font-family;
	margin: 0;
	padding: 0;
}
h1,
h2,
h3,
h4,
h5,
h6 {
	// font-family: var(--heading-font-family, "Lato");
	font-family: var(--heading-font-family);
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	text-rendering: optimizeLegibility;
}

p,
a,
input,
textarea {
	font-family: $paragraph-font-family;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	text-rendering: optimizeLegibility;
}

.root {
	margin: 0;
	padding: 0;
	@media (min-width: 767px) {
		position: relative;
		// padding-bottom: 318px;
		min-height: 100vh;
	}
	@media (min-device-width: 768px) and (max-device-width: 1024px) {
		// padding-bottom: 500px;
	}
}
.modal {
	z-index: 1070;
}

.container-fluid {
	max-width: 1170px;
}

@media (min-width: 576px) and (max-width: 712px) {
	.container,
	.container-sm {
		max-width: 100%;
	}
}

::-webkit-scrollbar-track:vertical {
	background-image: url('./scroll-bg-y.png');
	background-repeat: repeat-y;
}
::-webkit-scrollbar-track:horizontal {
	background-image: url('./scroll-bg-x.png');
	background-repeat: repeat-x;
}

.clickable-text {
	text-decoration: underline;
}

.btn,
button {
	font-family: var(--heading-font-family) !important;
	font-weight: 500;
	-webkit-overflow-scrolling: touch;
}

button.btn-primary {
	font-family: var(--heading-font-family) !important;
	font-weight: 400 !important;
}
button.btn-secondary {
	font-family: var(--heading-font-family) !important;
	font-weight: 400 !important;
	background-color: var(--cta-color-1);
}
button.btn-warning {
	font-family: var(--heading-font-family) !important;
	font-weight: 400 !important;
	background-color: var(--cta-color-1) !important;
	color: var(--black-color) !important;
	border: none !important;

	&:disabled {
		border: none !important;
	}
}

.btn-xs {
	padding: 0.125rem 0.5rem;
	font-size: 0.725rem;
	border-radius: 0.2rem;
}

.btn + .btn {
	margin-left: 15px;
}

.btn-primary:active,
.btn-primary:focus,
.btn-primary:not(:disabled):not(.disabled):active {
	background-color: var(--primary-color);
	border: none !important;
	box-shadow: none !important;
}
.custom-btn-svg:active,
.custom-btn-svg:focus,
.custom-btn-svg:not(:disabled):not(.disabled):active {
	background-color: transparent;
	border: none;
	box-shadow: none;
	appearance: none;
	border-radius: 0;
}
.dark-grey-button:active,
.dark-grey-button:focus,
.dark-grey-button:not(:disabled):not(.disabled):active {
	background-color: $header-font;
	border: 1px solid $header-font;
	box-shadow: 0 0 0 1px $header-font;
}
.card {
	border-radius: 4px;
	border: 0 none;
}

.search-view {
	.search-bar {
		background-color: $green-background;
		.search-home {
			border: none;
			margin-top: 0;
			margin-bottom: 0;
		}
	}
	.search-header-result {
		min-height: 100px;
		background-color: $white-color;

		h1::first-letter {
			text-transform: capitalize;
		}
		.breadcrums {
			padding: 10px 0;
			a::after {
				content: '>';
				color: $green-background;
				padding: 0 5px 0 10px;
				font-size: 12px;
			}
			.path-name {
				text-transform: capitalize;
			}
		}
		.content-header {
			padding: 0;
			text-transform: capitalize;
			font-family: Univers-65-bold;
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
		}
	}
}

.nowrap {
	white-space: nowrap;
}

.ellipsis {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.popover-body {
	border: 1px solid $light-green;
	color: $description-color;
	font-family: Univers-45;
	.description-detail {
		border-bottom: 1px solid $light-green;
		h5 {
			color: $dark-color;
			font-weight: 900;
			font-family: $paragraph-font-family;
		}
		p:first-child {
			font-family: $paragraph-font-family;
			font-weight: 900;
			font-size: 18px;
		}
	}
	.inspection-detail,
	.contact-detail,
	.delivery-detail {
		h5 {
			color: $dark-color;
			font-weight: 900;
			font-family: $paragraph-font-family;
		}
		padding-top: 15px;
		border-bottom: 1px solid $light-green;
	}
	.contact-detail {
		border-bottom: 0px solid $light-green;
	}
}
.no-pointer {
	pointer-events: none;
}

@media (min-width: 320px) and (max-width: 480px) {
	.search-view {
		.search-header-result {
			padding: 0 15px;
		}
		.sort-block {
			display: none;
		}
	}
	.view-header {
		height: auto;
	}
	.content-header {
		font-size: 1.8rem;
		display: -webkit-box;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;
		white-space: normal !important;
	}
}

.custom-control-input:checked ~ .custom-control-label::before {
	color: #fff;
	border-color: var(--cta-color-1);
	background-color: #fff;
}

.custom-switch .custom-control-input:checked ~ .custom-control-label::after {
	background-color: var(--cta-color-1);
}

/* iPads (portrait and landscape) ----------- */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
	/* STYLES GO HERE */
	.auctions-view {
		margin-top: 44px;
	}
	.content-header {
		font-size: 2.3rem;
	}
}

.hilight {
	animation: hilight 3s;
}

@keyframes hilight {
	from {
		background-color: var(--cta-color-1);
	}
	to {
		background-color: $white-color;
	}
}

.relative {
	position: relative;
}

.btn.social-btn {
	color: #223d38;
	padding: 7px 8px;
	background-color: #fff;
	border: 1px solid #223d38;
	&:hover,
	&:not(:disabled):not(.disabled):active {
		color: #223d38;
		border-color: #223d38;
		background-color: #fff;
	}
	& > .social-btn-content {
		width: 160px;
		margin: auto;
		display: flex;
		font-size: 10px;
		font-weight: normal;
		align-items: center;
		svg {
			width: 12px;
			height: 12px;
		}
		span {
			font-weight: bold;
			font-size: 12px;
			margin-left: 15px;
		}
	}
	&.social-btn-facebook {
		svg {
			fill: #337ab7;
			height: 16px;
			width: 16px;
		}
	}
}

.page-content {
	position: relative;
	min-height: calc(100vh - 155px);
	transition: opacity 0.5s;
	@media only screen and (min-device-width: 767px) and (max-device-width: 1024px) {
		min-height: max-content;
	}
}
.backdrop-content {
	opacity: 0.4;
	pointer-events: none;
	// background-color: black;
}
.tooltip {
	z-index: 2;
}

.search-result-cards-wrapper {
	width: 100%;
	max-width: 1188px;
}

// cards
// asset and auction
// shared by
// AssetItemGridCard.jsx
// AuctionCard.jsx
.assetAttributes {
	display: flex;
	border-bottom: 1.02857px solid #ededed;
	padding-left: 13px;
	padding-bottom: 12px;
	flex-wrap: wrap;
	align-items: center;
	margin-top: 5px;

	&__item {
		overflow: hidden;
		text-overflow: ellipsis;
		flex-basis: 50%;
		font-size: 16px;
		font-weight: 300;
		font-family: 'Roboto';
		margin-top: 6px !important;

		svg {
			fill: var(--cta-color-1);
			margin-right: 5px;
		}
	}
}

@media (min-width: 1025px) and (max-width: 1164px) {
	.assetAttributes {
		padding-top: 15px;
	}
}

.auctionAttributes {
	margin-top: auto;
	margin-bottom: 0;

	.download-auction-documents {
		.btn {
			margin-left: auto;
			margin-right: auto;
			margin-top: 20px;
			margin-bottom: 20px;
		}
	}

	&__actions {
		.btn-bidHistory {
			font-size: 14px;
		}
		button {
			padding: 0;
			font-size: 10px;
			height: initial;
			font-family: $paragraph-font-family;
			text-decoration: underline;
		}
	}

	&__timer {
		display: flex;
		justify-content: space-between;

		padding: 13px;

		border-bottom: 1.02857px solid #ededed;

		&-label {
			p {
				font-size: 12px;
				margin-bottom: 0;
			}
		}

		&-clock {
			width: 100%;

			.block-renderer {
				display: flex;
				justify-content: space-between;

				@media (min-width: 1024px) and (max-width: 1600px) {
					flex-direction: column;

					.div-timecount {
						margin: 10px 0;
					}
				}

				.div-time-label {
					align-items: center;
					font-size: 12px;

					.time-label {
						font-size: inherit;
					}
				}

				.div-time-heading {
					font-size: 14px;
					font-family: 'Roboto';
					font-weight: 600;
				}
			}
			.div-timecount,
			.countdown-labels {
				flex-basis: 50%;
			}
			.div-timecount {
				margin: 0;
				justify-content: flex-start;
				align-items: center;
			}
		}
	}

	&__currentBid {
		display: flex;
		align-items: center;

		width: 100%;
		padding: 10px 13px;

		color: #1d1d1d;
		text-align: left;

		border-radius: 16px;

		&-label {
			font-weight: 600;
			font-family: 'Roboto';
			font-size: 14px;
		}

		&-amount {
			display: flex;
			justify-content: space-between;
			align-items: center;

			font-weight: 600;
			font-size: 20px;
			font-family: 'Roboto';
		}

		> div {
			flex-basis: 50%;
		}
	}
}

.cardCurrentBid {
	width: 100%;
	height: 28px;
	color: #1d1d1d;
	margin: 8px 0;
	text-align: left;
	border-radius: 16px;
}

.assetGridCard {
	.placeBid {
		&__bidNow {
			padding: 10px 10px;
			width: initial;
			height: initial;
			font-size: 16px;
			font-weight: 500;
			font-family: var(--heading-font-family);
			background-color: var(--cta-color-1);
			border: none;
		}
		&__viewDetails {
			padding: 0;
			margin: 0;
			height: initial;

			font-size: 14px;
			font-family: $paragraph-font-family;
			color: black;
			text-decoration: underline;

			background-color: transparent;
		}
	}
}

// FORMS - START
html,
body {
	.enquireForm {
		max-width: 967px;
		height: auto;

		box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.07);
		border-radius: 10px;
		background-color: #fff;

		&__wrapper {
			padding: 45px 85px;
			background-color: #fff !important;

			@media (max-width: 767px) {
				padding: 45px 20px;
			}
		}

		.row {
			margin-bottom: 23px;
		}
	}

	// we use the select class as a wrapper for the select element
	// wrapper controls the caret down svg
	.select {
		position: relative;
		-webkit-overflow-scrolling: touch;

		select {
			appearance: none;
			padding: 0px 24px 0px 15px !important;
			border: 1px solid #000000;
			border-radius: 4px;
			font-size: 16px;
			font-family: 'Roboto';
			font-weight: 300;
			font-style: normal;
		}
		svg {
			position: absolute;
			right: 15px;
			top: 50%;
			transform: translateY(-50%);
		}
	}

	form {
		.form-group {
			width: 100%;

			&.floating-input > .dropdown {
				border-top: none;
				padding: 10px 0;

				button {
					padding: 10px 15px;
				}
			}
			.form-control {
				border: 1.05px solid #000000;
				border-radius: 4.2px;
				outline: none;
				appearance: none;
				transition: all 0.3s;
				border-radius: 4px;
				padding: 5px 10px;
				font-family: 'Roboto';
				font-weight: 300;
				font-size: 16px;
				color: #000000 !important;

				&.is-invalid {
					border: 1px solid;
					border-color: #ff0000;

					&:focus {
						border-color: #ff0000;
						box-shadow: none;
					}
				}
			}
		}
	}
}
// FORMS - END

// GRID - START
.gx-1 {
	margin-right: -10px;
	margin-left: -10px;

	> .col,
	> [class*='col-'] {
		padding-right: 10px;
		padding-left: 10px;
	}
}
// GRID - END

.align-items {
	&--center {
		align-items: center;
	}
}

// catalog view
// austions + assets
// use this to setup sidebar
// and the main content area
// dont use bootstrap cols
// we need custom widths for this
.search-result-view-content {
	display: flex;
	margin-left: -25px;
	margin-right: -25px;
}
.search-filter-available {
	max-width: 258px;
	width: 100%;
	margin-left: 25px;
	margin-right: 25px;
}

// .form-group.floating-input > .dropdown {
//     border-top: 1px solid rgba(0, 0, 0, 0.6);
// }

// .form-group input::placeholder,
// .form-group .dropdown-toggle,
// .form-group .dropdown-toggle span,
// .form-group .form-label
// {
//     font-size: 13px;
//     color: rgba(0, 0, 0, 0.6);
// }

.btn-primary:focus,
.btn-primary.focus {
	color: #fff;
	background-color: var(--primary-color);
	border-color: none;
	box-shadow: none;
	opacity: 0.5;
}

.btn.btn-warning .btn-warning.focus,
.btn-warning:focus,
.btn-warning:hover {
	background-color: var(--cta-color-1) !important;
	border: none !important;
}

.btn,
.nav-item a {
	text-decoration: none;
}

.assetDetails {
	.col-7 {
		max-width: 800px;
		flex-basis: 800px;

		@media (min-width: 1200px) and (max-width: 1480px) {
			max-width: unset;
			flex-basis: unset;
			max-width: 50%;
		}
		@media (max-width: 1200px) {
			margin: auto;
		}
	}
	.col-5 {
		max-width: 680px;
		flex-basis: 680px;

		@media (min-width: 1200px) and (max-width: 1480px) {
			max-width: unset;
			flex-basis: unset;
			max-width: 50%;
		}

		@media (max-width: 1200px) {
			margin: auto;
		}

		@media (max-width: 767px) {
			padding: 50px 100px;
		}
	}
	&__image {
		position: relative;
		color: $dark-color;

		.image-gallery-image {
			border-radius: 7px;
		}

		@media (min-width: 1201px) and (max-width: 1479px) {
			height: unset;
			margin-bottom: 2em;
		}

		@media only screen and (min-width: 768px) and (max-width: 1199px) {
			width: 100%;
			height: auto;
		}

		@media only screen and (max-width: 767px) {
			width: 100%;
			height: unset;
			margin-top: 10px;
		}
	}
	&__infoPanel {
		border-radius: 7px;
		background-color: #f7f7f7;

		.btn {
			padding-left: 24px;
			padding-right: 24px;
		}

		.countdownTimer {
			.countdown-labels {
				flex-basis: 45%;
			}

			.div-timecount {
				flex-basis: 55%;
			}
		}

		&-row {
			border-bottom: 1px solid #d4d4d4;
			padding-bottom: 20px;
			margin-bottom: 20px;
			margin-left: 30px;
			margin-right: 30px;

			&--mb-0 {
				margin-bottom: 0;
			}

			&--nb {
				border: none;
			}

			@media (max-width: 767px) {
				// padding-bottom: 28px;
				margin-bottom: 28px;
			}

			&:last-of-type {
				margin-bottom: 0;
			}
			h5 {
				margin-bottom: 20px;
			}
			.online-bidding-block {
				justify-content: flex-end;
				margin-right: 0;

				.online-svg {
					text-align: center;
					svg {
						margin-bottom: 10px;
						margin-right: 0;
					}
				}
			}

			.online-biddimg-block-mb {
				@media (max-width: 767px) {
					justify-content: flex-start;
					padding: 27px 0 0 20px;
				}
			}
		}

		&-wrapper {
			padding: 40px 0;

			@media only screen and (max-width: 767px) {
				padding: 20px 14px;
			}

			.product-specs {
				font-size: 16px;
				font-weight: 300;
				margin-top: 14px;

				border-bottom: 1px solid #d4d4d4;

				// Asset Detail View - Asset Attributes
				table {
					margin-bottom: 0;
				}

				@media only screen and (min-width: 320px) and (max-width: 767px) {
					margin-left: 10px;
					border-top: 1px solid #d4d4d4;
					padding-top: 30px;
					margin-top: 30px !important;
					border-bottom: 1px solid #d4d4d4 !important;
					margin-bottom: 28px !important;
				}

				@media only screen and (min-width: 768px) and (max-width: 1199px) {
					margin-left: 25px;
				}

				svg {
					fill: #272e43;
					width: 24px;
					height: 24px;
					margin-right: 5px;
				}

				table {
					tr {
						line-height: 2;

						td {
							padding: 5px 0;
						}
					}
				}
			}
			.bid-amnt {
				font-size: 12px;
				background-color: #f8f8f8;
				color: #000;
				border: none;
				width: 170px;

				p {
					margin: 0;

					letter-spacing: 0.6px;
					font-size: 12px;
					font-weight: 700;
					font-family: var(--heading-font-family);
				}

				.bidded {
					font-size: 36px;
					font-family: var(--heading-font-family);
					font-weight: 400;
				}

				span {
					font-size: 36px;
					font-family: var(--heading-font-family);
				}
			}
			.bidAmnt {
				> div {
					width: 45%;

					& + div {
						width: 55%;
					}
				}

				&__info {
					display: flex;
					flex-direction: column;
					justify-content: space-between;
				}

				&__options {
					.finance-options {
						display: flex;
						align-items: center;
						margin-top: 0;

						font-size: 12px;
						font-family: var(--heading-font-family);

						a {
							font-size: inherit;
							font-family: inherit;
							color: #000;
							text-decoration: underline;
						}

						svg {
							margin-right: 1em;
						}
					}
				}

				&__buttons {
					display: flex;
					flex-direction: column;
					gap: 10px;

					button {
						// margin-bottom: 12px;
						margin-left: 0;
						height: 49px;

						&:last-of-type {
							margin-bottom: 0;
						}

						&:active {
							color: unset !important;
						}
					}
				}

				@media (max-width: 767px) {
					width: 75%;
					margin: auto;

					&__info {
						text-align: left;
						width: 100% !important;

						.bid-amnt {
							width: 100%;
						}
					}

					&__options {
						margin: 1em 0;
					}

					&__buttons {
						width: 100% !important;
					}
				}

				@media (min-width: 450px) and (max-width: 767px) {
					width: 100%;
				}
			}
		}

		&-prodSpecs {
			@media (max-width: 767px) {
				width: 95%;
				margin: auto;
			}
		}

		&-title {
			font-size: 36px;
			font-family: var(--heading-font-family);
			margin-bottom: 20px;
		}

		&-conno {
			font-size: 14px;
			font-weight: 300;
			font-family: $paragraph-font-family;
			margin-bottom: 30px;
		}

		&-extras {
			display: flex;

			> a,
			button {
				margin-right: 10px !important;
				font-size: 12px;
				font-weight: 700;
				font-family: var(--heading-font-family);
				letter-spacing: 0.6px;
			}

			@media (max-width: 767px) {
				.download-auction-documents,
				.download-auction-documents .btn,
				a.btn-call-to-action,
				button.btn-call-to-action {
					height: 40px;
				}
			}
		}

		&-timeLeft {
			.countdown-labels {
				flex-basis: 45%;
			}
			.div-timecount {
				flex-basis: 55%;
			}

			> button.btn-call-to-action {
				@media (max-width: 767px) {
					margin: auto;
				}
			}
		}

		&-notify {
			.notify__wrapper {
				width: 55%;
				margin-left: auto;
			}

			button {
				width: 100%;
			}

			@media (min-width: 767px) and (max-width: 1400px) {
				width: 100%;

				.notify__wrapper {
					width: 100%;
				}
			}

			@media (max-width: 767px) {
				width: 92%;
				margin-top: 1em;

				.notify__wrapper {
					width: unset;
				}
			}
		}

		&-enquire {
			button {
				width: 100%;
			}
			.enquire-left {
				width: 45%;
				@media (max-width: 767px) {
					width: 100%;
				}
			}
			.enquire-right {
				width: 55%;

				@media (max-width: 767px) {
					width: 100%;
				}
			}
		}

		&-location {
			font-size: 16px;
			font-weight: 300;
			font-family: $paragraph-font-family;
		}

		&-auctionData {
			.row {
				margin-bottom: 20px;

				&:last-of-type {
					margin-bottom: 0;
				}
			}
			.label {
				font-size: 17px;
				font-family: var(--heading-font-family);
			}
			.val {
				font-weight: 300;
				font-size: 16px;
				font-family: $paragraph-font-family;
				white-space: nowrap;

				.view-count {
					padding-left: 0;
				}
			}
			.social-icons {
				@media (max-width: 767px) {
					display: none;
				}
			}
		}

		&--vertical {
			.assetDetails__infoPanel {
				&-wrapper {
					display: grid;
					padding-left: 30px;
					padding-right: 30px;
					min-height: 330px;
					grid-template-columns: repeat(4, minmax(0, 1fr));

					@media (max-width: 899px) {
						grid-template-columns: repeat(2, minmax(0, 1fr));
					}
					@media (max-width: 499px) {
						grid-template-columns: repeat(1, minmax(0, 1fr));
					}
				}
				&-title {
					font-size: 22px;
					@media (max-width: 1090px) {
						font-size: 22px;
					}
				}
				&-row {
					border-bottom: none;
					border-right: 1px solid #000;
					flex-basis: 100%;
					margin-bottom: 0;
					padding-bottom: 0;
					@media (max-width: 899px) {
						border-right: none;
					}

					//   @media (max-width: 1200px) {
					//     border-right: none;
					//     margin-bottom: 20px;
					//   }

					h5 {
						display: none;
					}

					> div {
						.row {
							margin-left: 0;
							margin-right: 0;
							background-color: #f8f8f8;
						}
					}

					div:not(.assetDetails__infoPanel-auctionData) {
						.row {
							margin-left: 0;
							margin-right: 0;

							[class^='col-'] {
								max-width: 100%;
								flex: 0 0 100%;
							}

							.bonus-btn {
								margin-top: 20px;
								background-color: var(--primary-color);
								width: 161px;
								color: #fff;
								display: flex;
								align-items: center;
								svg {
									margin-left: 8px;
								}
							}
						}
					}

					.assetDetails__infoPanel-auctionData {
						display: flex;
						flex-direction: column;
						flex: 1;

						.social-icons {
							margin-top: auto;
							margin-bottom: 0;
							display: flex;
						}
					}

					.online-bidding-block {
						justify-content: flex-start;
						align-items: flex-end;
					}

					.auctionSchedule {
						height: 100%;

						.row {
							height: 100%;
						}
						.col {
							display: flex;
							align-items: flex-end;
						}
					}
				}

				&-header {
					height: 100%;
					.row {
						height: 100%;
					}
				}
				&-meta {
					.view-count,
					.bids-count {
						margin: auto;
						width: 150px;

						span {
							margin-left: 25px;
						}
					}
				}
			}
		}

		// NOT Vertical - bonus button hidden from this section
		.assetDetails {
			&__infoPanel {
				&-row {
					.bonus-btn {
						display: none;
					}
				}

				&-extras {
					button {
						font-size: 12px;
						padding: 4px 10px;
						line-height: 1.3;
						height: 56px;
						white-space: unset;

						@media (max-width: 767px) {
							width: 50%;
							white-space: normal;
						}
					}

					.download-auction-documents {
						display: flex;
						margin-right: 10px;
						height: 56px;
						line-height: 1.3;

						@media (max-width: 767px) {
							width: 50%;
						}

						button {
							width: 100%;
							height: 56px;
						}
					}
				}
				&-buyNow {
					display: flex;

					&-left {
						width: 45%;

						.title {
							font-size: 14px;
							font-weight: 400;
							text-align: left;
						}

						.btn-under {
							color: #000;
							font-size: 36px;
							text-align: left;
						}
					}
					&-right {
						width: 55%;

						.buy-now {
							width: 100%;
							button {
								width: 100%;
								&:focus {
									box-shadow: none !important;
									opacity: 0.9;
								}

								&[disabled] {
									background-color: red;
								}
							}
						}
					}
				}
			}
		}
	}

	//Asset Detail Description Section (shares assetDetail classname)

	&.desc-col {
		.col-5 {
			@media (max-width: 767px) {
				padding: 0;
			}
		}
	}
}

.auctionSchedule {
	label {
		margin-bottom: 10px;

		font-size: 19px;
		font-weight: 500;
		font-family: var(--heading-font-family);
	}
	&__time {
		background-color: var(--primary-color);
		border: none;
		font-size: 17px;

		font-weight: 400;
		font-family: var(--heading-font-family);

		color: #fff;
		padding: 5px 10px;
		border-radius: 7px;
		text-align: center;
		@media (max-width: 1090px) {
			font-size: 0.9rem;
		}

		@media (max-width: 767px) {
			background-color: var(--cta-color-1);
			color: #000;
		}
	}
	&__close {
		@media (max-width: 767px) {
			padding-top: 28px;
			// margin-bottom: 28px;
			padding-bottom: 40px;
		}
	}
}

// Bootstrap Overwrites*****************************

.dropdown-item {
	&.active,
	&:active {
		background-color: var(--primary-color);
	}
}

.form-control {
	&:focus {
		box-shadow: none;
		border-color: #000;
	}
}

.btn-outline-primary {
	border: 1px solid var(--primary-color);
	color: var(--primary-color);
	transition: all 0.3s ease-in-out;

	&:hover {
		border: 1px solid var(--primary-color);
		color: var(--primary-color);
		opacity: 0.8;
		background-color: #fff;
	}

	&:not(:disabled):not(.disabled):active:focus,
	&:not(:disabled):not(.disabled).active:focus,
	&:focus,
	&.focus {
		box-shadow: none;
	}

	&:not(:disabled):not(.disabled):active,
	&:not(:disabled):not(.disabled).active {
		background-color: #00000099;
		border-color: #00000000;
		overflow: hidden;

		svg {
			fill: #fff;
		}
	}
}

.show > .btn-outline-primary.dropdown-toggle:focus {
	box-shadow: none;
}

.show > .btn-outline-primary.dropdown-toggle {
	background-color: #00000099;
	border-color: var(--primary-color);

	svg {
		fill: #fff;
	}
}

.switchColor {
	color: green !important;
	background-color: var(--black-color);
}

.biddingClosed {
	margin-left: auto;
	margin-right: auto;

	svg {
		width: 24px;
		height: 24px;
		margin-left: 5px;
	}
}
// hide werid white line under triangle, bonus time modal
.bs-popover-bottom .popover-header::before {
	display: none;
}

.custom-control-input:checked ~ .custom-control-label::before {
	color: #000;
	border-color: #000;
	background-color: #fff;
}

.custom-switch .custom-control-input:checked ~ .custom-control-label::after {
	background-color: #000;
}

.btn {
	&:hover {
		opacity: 0.8;
	}
}

.atlasAuctiontails {
	@media (max-width: 767px) {
		.atlasViewCount {
			display: none;
		}
		.online-bidding-block {
			.online-svg {
				margin-top: 0;
				display: flex;
				align-items: center;
			}
		}
		.val {
			font-size: 20px;
			padding: 0;
			font-weight: 300e;
		}
	}
}

// pagination
.pagination-row {
	display: flex;
	align-items: center;

	padding: 20px 0;
	background-color: #f6f6f6 !important;
	margin-bottom: 50px;

	&--bottom {
		margin-top: 30px;
	}

	@media (max-width: 1200px) {
		padding-bottom: 25px;
	}

	.pagination-row-container {
		display: flex;
		align-items: center;
		flex: 1;
		max-width: 1480px;
		margin: 0 auto;

		@media (min-width: 1600px) {
			padding-left: 20px;
		}
		@media (max-width: 1600px) {
			max-width: 1460px;
			padding-left: 20px;
			padding-right: 20px;
		}
		@media (max-width: 1200px) {
			display: block;
		}

		.pagination {
			.page-item {
				a {
					font-size: 16px;
					font-weight: 300;
					font-family: 'Roboto' !important;
				}

				.page-link {
					svg {
						stroke: #000;
					}
				}
			}
		}
	}

	p,
	input,
	a,
	button {
		font-size: 14px;
	}

	.asset-cards-pagination {
		display: flex;
		align-items: center;
		flex-grow: 1;

		> div:not(.btn-group) {
			width: 100%;
		}

		.btn-group:hover {
			opacity: 1;
		}

		button:hover {
			svg {
				fill: #272e43;
				opacity: 0.5;
			}
		}

		@media (max-width: 757px) {
			.viewToggle {
				display: none;
			}
		}

		@media (max-width: 1200px) {
			display: block;
			width: 100%;
			position: relative;

			.pagination-container {
				display: block;
				width: 100%;
				margin: 0;
				text-align: center;

				> p {
					margin-right: 0;
				}

				.pagination {
					margin: 0 10px 17px 10px;
					justify-content: center;
				}
			}

			> .btn-group {
				margin: auto;
				position: absolute;
				bottom: 0;
				left: 49px;
			}
		}
	}

	.pagination-container {
		height: unset;
		font-weight: 400;
		align-items: center;
		white-space: nowrap;
		padding: unset;
		justify-content: space-between;
		background-color: unset;
		box-shadow: none;
		width: 94%;
		margin-left: 4%;

		p {
			font-weight: 300;
			font-size: 16px;
			font-family: 'Roboto' !important;
		}
	}

	.search-filters {
		button {
			margin-bottom: 0;
			min-width: 161px;
		}

		@media (max-width: 1200px) {
			margin: auto;
			margin-bottom: 1em;
			width: 100%;

			> button {
				max-width: 183px;
			}
		}
	}

	.form-group.floating-input {
		width: 110px;
	}

	.btn-group .btn {
		background-color: transparent !important;

		svg {
			fill: #272e43;
		}
	}
}
// pagination

button.watch-list__rounded {
	border-radius: 50%;
	height: 38px;
	width: 38px;
	border: 1px solid #9e9c9c;
	background-color: #fff;
	padding: 0;
	flex-shrink: 0;
	background: rgba(255, 255, 255, 0.8);
	box-shadow: 0px 0px 2.05714px rgba(0, 0, 0, 0.15);
	margin: 0;

	@media (max-width: 757px) {
		height: 20px;
		width: 20px;
	}
}

.related-assets-cards {
	button.watch-list {
		height: 40px;
		width: 40px;
	}
}

.pagination-row--no-save-search {
	.pagination-container {
		margin-left: 0;
	}
}

.placebid {
	&--bottom {
		// box-shadow: 0px -10px 15px 0px rgba(0, 0, 0, 0.1);
		box-shadow: 0px -3px 25px rgba(0, 0, 0, 0.13);

		.card-header {
			height: unset;
			.place-bid-btn {
				border-bottom-left-radius: 0;
				border-bottom-right-radius: 0;
				position: absolute;
				top: -33px;
			}
		}
	}
	&--inline {
		.card-title {
			position: absolute;
			right: 0;
			top: -25px;
		}
		.assetAttributes {
			padding-left: 0;
		}
		.total-fee {
			margin-bottom: 15px;
		}
		.bidInput__label,
		.placebid--inline .current-bid__label,
		.block-renderer .div-time-label .time-label {
			font-size: 14px;
			font-weight: 600;
		}
		.bid-input-group.input-group {
			margin-bottom: 15px;
		}
		.assetGridCard__images {
			padding: 0;
			margin-bottom: 15px;
		}
		.placebid {
			&__title {
				font-size: 21px;
				font-weight: 500;
			}
		}
		.current-bid {
			display: flex;

			&__label {
				flex-basis: 50%;
				font-size: 14px;
				font-weight: 600;
			}
			&__amount {
				font-size: 20px;
			}
		}
		.time-remaining {
			margin-bottom: 20px;
		}
		.bidInput {
			display: flex;

			&__label {
				flex-basis: 50%;
			}
		}
	}
}

.atlasHighestBidder {
	display: flex;
	align-items: center;
	padding: 4px 0;
	width: max-content;
	font-size: 12px;
	font-weight: 600;
	color: #000;
	background-color: transparent;
	border: 1px solid var(--primary-color);
	border-radius: 6px;
	flex-grow: 1;
	flex-shrink: 0;
	margin: 0 0 15px 0;
	width: 100%;
	justify-content: center;
}
.bidPanel {
	&--bottom {
	}
}

.logo {
	&--header {
		margin-top: 10px;
		margin-bottom: 10px;
	}
}

.btn.slt-dark:active,
.btn.slt-dark:focus,
.btn.slt-dark:not(:disabled):not(.disabled):active {
	background-color: var(--primary-color);
	box-shadow: none;
}

.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before,
.custom-control-input.is-valid:checked ~ .custom-control-label::before {
	background-color: var(--primary-color);
	box-shadow: none;
}

.page-content {
	.title-search {
		display: flex;
		justify-content: space-between;
		background-color: #fff;
		margin-top: 0;
		margin-bottom: 40px;
		font-weight: 500;
		margin-bottom: 0;
		align-items: center;

		@media (min-width: 1200px) {
			padding-bottom: 25px;
		}

		@media (max-width: 767px) {
			flex-direction: column;
			margin-top: 30px;
		}
		h1 {
			font-size: 54px;
			font-weight: 500;
			margin-bottom: 40px;
			@media (max-width: 767px) {
				font-size: 36px;
				margin-bottom: 25px;
			}
			@media screen and (min-width: 900px) {
				max-width: 30vw;
				margin-bottom: 0;
			}
		}
		form {
			padding: 15px 0px;
		}
	}
}

// timer, tag and buttons
// tags already match

.card {
	&--status-open {
		.assetAttributes__item {
			.address-details svg {
				fill: var(--cta-color-1);
			}

			.bid-type svg {
				fill: var(--cta-color-1);
			}
		}
		.countdown-timer {
			.div-timecount .time-div {
				fill: var(--cta-color-1);
				color: var(--primary-color);
			}
		}

		.img-bottom button.view-btn {
			background: var(--cta-color-1);
			color: var(--primary-color);

			&:hover {
				background: var(--cta-color-1);
				color: var(--primary-color);
				opacity: 0.8;
			}
		}
	}
	&--status-closed {
		.assetAttributes__item {
			.address-details svg {
				fill: var(--closed-tag-color);
			}

			.bid-type svg {
				fill: var(--closed-tag-color);
			}
		}
		.countdown-timer {
			.div-timecount .time-div {
				background-color: var(--closed-tag-color);
				color: #fff;
			}
		}

		.img-bottom button.view-btn {
			background: var(--closed-tag-color);

			&:hover {
				background: var(--closed-tag-color);
				opacity: 0.8;
			}
		}
	}
	&--status-soon {
		.assetAttributes__item {
			svg {
				fill: var(--opening-soon-tag-color);
			}
		}
		.countdown-timer {
			.div-timecount .time-div {
				background-color: var(--opening-soon-tag-color);
				color: #fff;
			}
		}

		.img-bottom button.view-btn {
			background: var(--opening-soon-tag-color);
			&:hover {
				background: var(--opening-soon-tag-color);
				opacity: 0.8;
			}
		}
		.placeBid {
			.placeBid__bidNow {
				background: var(--opening-soon-tag-color);
				color: #fff;

				&:hover {
					background: var(--opening-soon-tag-color);
					opacity: 0.8;
				}
			}
		}
	}
}

// auctions page
// container needsd to be 100% width
// no sidebar
.search-result-cards-wrappers {
	width: 100%;
}

.AssetCard {
	.isBonusTime--true {
		background-color: #ddffe7;

		.time-div {
			border: 2px solid #272e43;
		}
	}
}
